<template>
  <div class="home">
    <h1>Site CRA-RS</h1>
    <div class="links">
      <router-link to="/paginas"><font-awesome-icon icon="file"/><br>Páginas</router-link>
      <router-link to="/camaras"><font-awesome-icon icon="university"/><br>Câmaras</router-link>
      <router-link to="/banner"><font-awesome-icon icon="image"/><br>Banner</router-link>
      <router-link to="/itens-destaque"><font-awesome-icon icon="th-large"/><br>Itens em Destaque</router-link>
      <router-link to="/noticias"><font-awesome-icon icon="keyboard"/><br>Notícias CRA</router-link>
      <router-link to="/artigos"><font-awesome-icon icon="file-pdf"/><br>Artigos</router-link>
<!--      <router-link to="/noticias-camaras"><font-awesome-icon icon="keyboard"/><br>Notícias das Câmaras</router-link>-->
      <router-link to="/clube-de-servicos"><font-awesome-icon icon="star"/><br>Clube de Serviços</router-link>
      <router-link to="/revista-master"><font-awesome-icon icon="file-pdf"/><br>Revista Master</router-link>
      <router-link to="/parceiros"><font-awesome-icon icon="handshake"/><br>Parceiros</router-link>
      <router-link to="/cra-no-interior"><font-awesome-icon icon="city"/><br>CRA no Interior</router-link>
     <router-link to="/cra-na-imprensa"><font-awesome-icon icon="newspaper"/><br>CRA na Imprensa</router-link>
     <router-link to="/eventos"><font-awesome-icon icon="calendar-week"/><br>Eventos</router-link>
<!--      <router-link to="/editais"><font-awesome-icon icon="file-alt"/><br>Editais</router-link>-->
      <router-link to="/contato"><font-awesome-icon icon="address-book"/><br>Contato</router-link>
      <router-link to="/newsletter"><font-awesome-icon icon="envelope"/><br>Newsletters</router-link>
      <router-link to="/galerias"><font-awesome-icon icon="camera"/><br>Galerias de Fotos</router-link>
      <router-link to="/pactoglobal"><font-awesome-icon icon="globe"/><br>Pacto Global</router-link>
      <router-link to="/portalcolaborador"><font-awesome-icon icon="laptop-house"/><br>Portal do Colaborador</router-link>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// const axios = require('axios').default;

export default {
  name: 'home',
  components: {},
  methods: {

  }
}
</script>

<style scoped lang="scss">
  @import "../assets/scss/global";
  @import "../assets/scss/buttons";
  @import "../assets/scss/blocks";

  .home {
    height: 100vh;
    overflow-y: scroll;
    padding: 20px 0;

    .links {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 20px;
      padding: 0 50px;
      margin-top: 50px;

      a {
        display: block;
        background-color: $alt;
        color: #fff;
        font-weight: bold;
        text-decoration: none;
        padding: 20px;
        border-radius: 10px;
        transition: background-color 0.3s;

        &:hover {
          background-color: $primary;
        }

        .svg-inline--fa {
          font-size: 2rem;
          margin-bottom: 10px;
        }
      }
    }
  }

</style>
