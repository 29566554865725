<template>
  <div class="pages">
    <h1>Editar Portal Colaborador</h1>
    <button class="btn outline back-btn" v-on:click="$router.back()">Voltar</button>
    <p v-if="loading">Carregando dados...</p>
    <div class="wrapper" v-if="!loading">    
      <div style="text-align: left">
        <router-link to="/noticias-portal/colaborador" class="btn" style="display: inline-block"><font-awesome-icon icon="edit"/> Notícias do Portal do Colaborador</router-link>  
        <router-link to="/galerias-colaborador" class="btn" style="display: inline-block"><font-awesome-icon icon="edit"/> Galerias de Fotos do Portal do Colaborador</router-link>
        <router-link to="/paginas/editar/486" class="btn" style="display: inline-block"><font-awesome-icon icon="edit"/> Arquivos Internos</router-link>
        <router-link to="/paginas/editar/695" class="btn" style="display: inline-block"><font-awesome-icon icon="edit"/> Nossa Gente - RH</router-link>
      </div>      
      <br><br>
      <div>
        <label>Contatos / Ramal</label>
        <div class="editor-wrapper">
          <editor
              ref="editor_content_1"
              autofocus
              holder-id="codex-editor-content-1"
              save-button-id="save-button-1"
              :init-data="initContacts"
              @save="save1"
              @ready="onReady1"
              @change="onChange1"
          />
        </div>
      </div>
      <div>
        <label>Aniversariantes do mês</label>
        <div class="editor-wrapper">
          <editor
              ref="editor_content_2"
              autofocus
              holder-id="codex-editor-content-2"
              save-button-id="save-button-2"
              :init-data="initBirthdays"
              @save="save2"
              @ready="onReady2"
              @change="onChange2"
          />
        </div>
      </div>      
      <div>
        <label for="imgDsktp">Aniversariante destaque 1</label>
        <input type="file" id="imgDsktp" v-on:change="imgB1Changed" accept="image/*" ref="b1">        
        <p v-if="savingB1">Fazendo upload...</p>
        <img v-if="item.birthday1" :src="$mediaBaseUrl + item.birthday1">
        <br>
        <button v-show="item.birthday1" class="btn" style="background-color: red;" v-on:click="item.birthday1 = ''" :disabled="saving">Remover</button>
      </div>
      <div>
        <label for="imgMbl">Aniversariante destaque 2</label>
        <input type="file" id="imgMbl" v-on:change="imgB2Changed" accept="image/*" ref="b2">        
        <p v-if="savingB2">Fazendo upload...</p>
        <img v-if="item.birthday2 !== ''" :src="$mediaBaseUrl + item.birthday2">
        <br>
        <button v-show="item.birthday2" class="btn" style="background-color: red;" v-on:click="item.birthday2 = ''" :disabled="saving">Remover</button>
      </div>   
      <div>
        <label for="fileYB">Aniversariantes do ano (enviar pdf)</label>
        <input type="file" id="fileYB" v-on:change="fileYBChanged" accept="application/pdf" ref="fileYB">        
        <p v-if="savingYB">Fazendo upload...</p>
        <a v-if="item.yearBirthdays !== ''" :href="$mediaBaseUrl + item.yearBirthdays" target="_blank"></a>
        <br>
      </div>    
      <br>
      <button class="btn save-btn" v-on:click="saveItem" :disabled="saving">{{ saving ? 'Salvando...' : 'Salvar' }}</button>
    </div>    
  </div>
</template>

<script>
// @ is an alias to /src
const axios = require('axios').default;

export default {
  name: 'edititem',
  data() {
    return {
      loading: true,
      saving: false,
      deleting: false,
      savingImg: false,
      savingB1: false,
      savingB2: false,
      savingYB: false,
      itemId: this.$route.params.id,
      item: {        
        contacts: '',
        birthdays: '',
        birthday1: '',
        birthday2: '',        
        yearBirthdays: '',        
      },
      initContacts: {
        blocks: []
      },
      initBirthdays: {
        blocks: []
      },      
    }
  },
  mounted() {
    let self = this;
    axios.get(this.$apiUrl + 'partnerportal')
            .then(response => {
              console.log(response.data.item);
              self.item = response.data.item;
              self.item.contacts = JSON.parse(self.item.contacts);
              self.item.birthdays = JSON.parse(self.item.birthdays);                          

              self.initContacts = self.item.contacts;
              self.initBirthdays = self.item.birthdays;             
              self.loading = false;
            })
            .catch(error => {
              this.$alert('', 'Erro', 'error');
              // eslint-disable-next-line no-console
              console.log(error.response.data.error);
              self.loading = false;
            });
  },
  methods: {
    saveItem() {
      let error = false;

      if (!error) {
        this.saving = true;
        let self = this;

        let itemToSave = Object.assign({}, this.item);
        itemToSave.contacts = JSON.stringify(itemToSave.contacts);        
        itemToSave.birthdays = JSON.stringify(itemToSave.birthdays);                

        axios.put(this.$apiUrl + 'partnerportal', itemToSave)
                .then(response => {
                  self.saving = false;
                  this.$alert(response.data.message, 'Sucesso', 'success').then(() => {});
                })
                .catch(error => {
                  this.$alert(error.response.data.message, 'Erro', 'error');
                  // eslint-disable-next-line no-console
                  console.log(error.response.data.error);
                  self.saving = false;
                });
      }
    },        
    save1(response) {      
      this.item.contacts = response;
    },
    onReady1() {
      this.$refs['editor_content_1'].save();
    },
    onChange1() {
      this.$refs['editor_content_1'].save();
    },    

    save2(response) {      
      this.item.birthdays = response;
    },
    onReady2() {
      this.$refs['editor_content_2'].save();
    },
    onChange2() {
      this.$refs['editor_content_2'].save();
    }, 

    async imgB1Changed() {
      let self = this;
      let file = this.$refs.b1.files[0];
      self.savingB1 = true;

      if (file) {
        const formData = new FormData();
        formData.append('file', file);

        axios.post(this.$apiUrl + 'files/upload', formData)
                .then(response => {
                  self.item.birthday1 = response.data.file.key;
                  self.savingB1 = false;
                })
                .catch(error => {
                  console.log(error);
                  self.savingB1 = false;
                });
      }
    },
    async imgB2Changed() {
      let self = this;
      let file = this.$refs.b2.files[0];
      self.savingB2 = true;

      if (file) {
        const formData = new FormData();
        formData.append('file', file);

        axios.post(this.$apiUrl + 'files/upload', formData)
                .then(response => {
                  self.item.birthday2 = response.data.file.key;
                  self.savingB2 = false;
                })
                .catch(error => {
                  console.log(error);
                  self.savingB2 = false;
                });
      }
    },
    async fileYBChanged() {
      let self = this;
      let file = this.$refs.fileYB.files[0];
      self.savingYB = true;

      if (file) {
        const formData = new FormData();
        formData.append('file', file);

        axios.post(this.$apiUrl + 'files/upload', formData)
                .then(response => {
                  self.item.yearBirthdays = response.data.file.key;
                  self.savingYB = false;
                })
                .catch(error => {
                  console.log(error);
                  self.savingYB = false;
                });
      }
    },
  },
}
</script>

<style scoped lang="scss">
  @import "../../assets/scss/global";
  @import "../../assets/scss/buttons";

  .pages {
    height: 100vh;
    overflow-y: scroll;
    padding: 20px;
    position: relative;

    .back-btn {
      position: absolute;
      top: 20px;
      left: 20px;
      border-radius: 10px;
    }

    .wrapper {
      padding: 50px 200px;
      @media screen and (max-width: 800px) {
        padding: 50px;
      }
    }

    .editor-wrapper {
      text-align: left;
      background-color: #fff;
      border: 1px solid $primary;
      border-radius: 10px;
    }

    label {
      display: block;
      text-align: left;

      &:nth-of-type(1) {
        margin-top: 10px;
      }
    }

    input, select, textarea {
      padding: 10px;
      width: 100%;
      border: 1px solid $primary;
      border-radius: 10px;
      font-size: 1rem;
    }

    select {
      -webkit-appearance: none;
    }

    .save-btn {
      border-radius: 10px;
    }

    img {
      max-width: 200px;
      height: auto;
    }
  }

  .delete-btn {
    background-color: #b13336;
    position: absolute;
    top: 20px;
    right: 20px;
    border-radius: 10px;

    &:hover {
      background-color: darken(#b13336, 20%);
    }
  }
</style>
